import * as React from 'react';
import * as styles from './footer.module.scss';
import { Link } from 'gatsby';

const Footer = () => {
  return (
    <footer className={`container pt-5  mt-5 ${styles.footerWrapper}`}>
      <div className="row pt-1 pb-4">
        <div className="col-4 d-flex justify-content-start">
          &copy; 2022 Alberto Zotti
        </div>
        <div className={`col-4 d-flex justify-content-center`}>
          <a
            className="pe-3"
            href="https://www.facebook.com/Cloppus-136434525270726"
            target="_blank"
          >
            fb
          </a>
          <a
            className="pe-3"
            href="https://www.instagram.com/cloppus.design/"
            target="_blank"
          >
            inst
          </a>
          <a
            className="pe-3"
            href="https://www.pinterest.it/cloppus/"
            target="_blank"
          >
            pin
          </a>
          <a href="https://www.twitter.com/cloppus_design/" target="_blank">
            twt
          </a>
          {/* <a href="#email">subscribe to mailing list</a> */}
        </div>
        <div className="col-4 d-flex justify-content-end">
          <Link to="/privacy">Privacy Policy</Link>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
