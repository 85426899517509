import * as React from 'react';
import '../style.scss';
import './layout.scss';

import Header from './header/header';
import Footer from './footer/footer';

const Layout = ({ children }) => {
  return (
    <>
      <Header />
      <div className="container-fluid p-0">{children}</div>
      <div className="container-fluid p-0">
        <Footer />
      </div>
    </>
  );
};

export default Layout;
