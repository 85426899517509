import * as React from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';
import {
  GatsbyImage,
  getImage,
  IGatsbyImageData,
  ImageDataLike,
} from 'gatsby-plugin-image';
import Layout from '../components/layout';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

const ContactsPage = () => {
  return (
    <StaticQuery
      query={graphql`
        query ContactsPage {
          page: contentfulPage(name: { eq: "Contacts" }) {
            name
            excerpt {
              raw
            }
            content {
              raw
            }
            coverImage {
              file {
                url
                fileName
              }
              gatsbyImageData(
                placeholder: DOMINANT_COLOR
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      `}
      render={(data) => {
        let page: IPageProps = data.page;
        const image: IGatsbyImageData | undefined = getImage(page.coverImage);

        return (
          <Layout>
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <h1>{page.name}</h1>
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-md-8">
                  {image && (
                    <GatsbyImage
                      image={image}
                      objectFit="cover"
                      alt={page.name}
                    />
                  )}
                </div>
                <div className="col-12 col-md-4 mt-4 mt-md-0 d-flex flex-column justify-content-end">
                  <div className="row">
                    <div className="col-6">
                      <strong>General inquiries:</strong>
                    </div>
                    <div className="col-6 text-end">
                      <div>
                        <a
                          className="contact-info"
                          href="mailto:info@cloppus.com"
                        >
                          info@cloppus.com
                        </a>
                      </div>
                      <div>
                        <a className="contact-info" href="tel:+393498241451">
                          +39 349 82 41 451
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="row pt-5">
                    <div className="col-6">
                      <strong>Workshop:</strong>
                    </div>
                    <div className="col-6 text-end">
                      via Carpane, 47
                      <br />
                      35010, Vigonza <br />
                      Italy
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Layout>
        );
      }}
    ></StaticQuery>
  );
};

export default ContactsPage;
