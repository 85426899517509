import React, { useState } from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';
import * as styles from './header.module.scss';

//Assets
import Logo from '../../images/cloppus_logo.svg';
import NavIcon from '../../images/nav_icon.svg';
import NavIconClose from '../../images/nav_icon_close.svg';

interface HeaderProps {
  hideNav?: boolean;
}

const Header = (props: HeaderProps) => {
  return (
    <StaticQuery
      query={graphql`
        query SitePagesQuery {
          allContentfulPage(sort: { order: ASC, fields: order }) {
            totalCount
            nodes {
              name
              slug
            }
          }
        }
      `}
      render={(data) => {
        //console.log(data.allContentfulPage.nodes);
        return (
          <div
            className={`header-container container-fluid p-0 ${styles.headerContainer}`}
          >
            <header className={`container pb-4`}>
              <div className="row">
                <div
                  className={`col-12 pt-5 pb-3 d-flex justify-content-center ${styles.headerTopRow}`}
                >
                  <div className={`flex-grow-1 ${styles.logoWrapper}`}>
                    <div className="d-flex justify-content-center">
                      {/* <img className={styles.logo} src={Logo} /> */}
                      <Link to="/">
                        <Logo />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              {props.hideNav ? (
                <div className="row justify-content-center">
                  <div className="col-10 d-flex justify-content-center pt-5">
                    design & crafts
                  </div>
                </div>
              ) : (
                <nav className={`row`}>
                  <div className="col-12 d-flex justify-content-center py-3">
                    {data.allContentfulPage.nodes.map((link: any) => (
                      <span key={link.name} className="px-4">
                        <Link
                          to={(link.slug !== '/' ? '/' : '') + link.slug}
                          activeClassName="active"
                        >
                          {link.name.toLowerCase()}
                        </Link>
                      </span>
                    ))}
                  </div>
                </nav>
              )}
            </header>
          </div>
        );
      }}
    />
  );
};

export default Header;
